<template>
  <form-wizard
      ref="refFormWizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Enregistrer"
      back-button-text="Précédent"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
      @on-complete="onSubmit"
  >
    <!-- product tab -->
    <tab-content
        title="Produits"
        icon="feather icon-list"
    >
      <store-view-items-checkout-step-cart @next-step="formWizardNextStep"/>
    </tab-content>

    <!-- information tab -->
    <tab-content
        title="Renseignements"
        icon="feather icon-user-check"
    >
      <store-view-items-checkout-step-address
          :details.sync="checkoutDetails"
          @next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- checkout tab -->
    <tab-content
        title="Valider"
        icon="feather icon-check-circle"
    >
      <store-view-items-checkout-step-recap
          :details="checkoutDetails"
          @next-step="formWizardNextStep"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import {computed, ref, watch} from '@vue/composition-api'
import StoreViewItemsCheckoutStepRecap from './StoreViewItemsCheckoutStepRecap.vue'
import StoreViewItemsCheckoutStepCart from './StoreViewItemsCheckoutStepCart.vue'
import StoreViewItemsCheckoutStepAddress from './StoreViewItemsCheckoutStepAddress.vue'
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import router from "@/router";

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    StoreViewItemsCheckoutStepCart,
    StoreViewItemsCheckoutStepAddress,
    StoreViewItemsCheckoutStepRecap,
  },
  setup() {
    const refFormWizard = ref(null);
    const toast = useToast();
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    // ? This is just dummy details
    const checkoutDetails = ref({
      concerned_project: null,
      dispatch_date: null,
      return_date: null,
      note: '',
    });

    const releaseProducts = computed(() => store.getters["storeManagement/cartItems"]);

    const showToast = (messages, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: messages,
          icon: icon,
          variant: variant,
        },
      });
    };

    // watch(checkoutDetails, val => {
    //   console.log('infos', [val, store.getters["storeManagement/cartItems"]])
    // }, {
    //   deep: true,
    //   immediate: true
    // })

    const onSubmit = async () => {
      try {
        if (releaseProducts.value.length <= 0) {
          showToast("Aucun produit n'a été ajouté en sortie.", "AlertCircleIcon", "danger");
          return;
        }

        // Map release products data
        const releaseProductsMapped = releaseProducts.value.map((product) => {
          return {
            uid: product.uid,
            qty: product.qty,
            ...(product.details),
          };
        });

        // Prepare request data
        const requestData = {
          ...checkoutDetails.value,
          release_products: releaseProductsMapped,
        };
        await store.dispatch("stock/saveProductsRelease", requestData);
        if (store.getters["stock/errors"] != null) {
          showToast(store.getters["stock/errors"].message, "AlertCircleIcon", "danger");
          return;
        }
        showToast("La sortie a été effectuée avec succès.", "CheckCircleIcon", "success");
        await router.push({name: 'apps-store-details', params: {id: router.currentRoute.params.id}});

      } catch (e) {
        showToast("Une erreur est survenue lors de la sortie.", "AlertCircleIcon", "danger");
      }
    };

    return {
      releaseProducts,
      refFormWizard,
      formWizardNextStep,

      onSubmit,
      checkoutDetails,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
