<template>
  <validation-observer #default="{ handleSubmit, invalid }" ref="refFormObserver">
    <b-form
        class="list-view product-checkout"
        @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Ajout de renseignement</b-card-title>
          <b-card-text class="text-muted mt-25">
            Veuillez renseigner toutes informations utiles et nécessaires pour
            la justification de ce déstockage.
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- concerned project -->
            <b-col cols="12" md="6">
              <validation-provider
                  #default="validationContext"
                  name="concerned_project"
                  rules="required"
              >
                <b-form-group
                    label="Projet concerné par la sortie :"
                    label-for="concerned_project"
                    :state="getValidationState(validationContext)"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="concerned_project"
                      rules="required"
                  >
                    <v-select
                        v-model="details.concerned_project"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="projectsList"
                        :reduce="(o) => o.value"
                        :clearable="true"
                        placeholder="Sélectionnez ou Recherchez votre projet"
                        input-id="concerned_project"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Pas de résultat pour <em>{{ search }}</em
                        >.
                        </template>
                        <em v-else style="opacity: 0.5">Recherche en cours...</em>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <hr>
          <b-row>
            <!-- Dispatch date -->
            <b-col cols="12" md="6">
              <validation-provider
                  #default="validationContext"
                  name="dispatch_date"
                  rules="required"
              >
                <b-form-group
                    label="Date de sortie :"
                    label-for="dispatch_date"
                    :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                      id="dispatch_date"
                      v-model="details.dispatch_date"
                      :config="flatConfig"
                      class="form-control"
                      placeholder="Date de sortie..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Return date -->
            <b-col cols="12" md="6">
              <validation-provider
                  #default="validationContext"
                  name="return_date"
                  rules=""
              >
                <b-form-group
                    label="Date de retour :"
                    label-for="return_date"
                    :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                      id="return_date"
                      v-model="details.return_date"
                      :config="flatConfig"
                      class="form-control"
                      placeholder="Date de retour..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Note -->
            <b-col lg="6" cols="12">
              <validation-provider
                  #default="validationContext"
                  name="Note"
                  rules="required"
              >
                <b-form-group label="Note :" label-for="note">
                  <b-form-textarea
                      id="note"
                      v-model="details.note"
                      :state="getValidationState(validationContext)"
                      placeholder="Note..."
                      rows="1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Submit Button -->
            <!-- <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
              >
                Save and Deliver Here
              </b-button>
            </b-col> -->
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <div class="customer-card">
        <b-card>
          <!--          <b-card-text class="mb-0"> 9447 Glen Eagles Drive </b-card-text>-->
          <!--          <b-card-text>Lewis Center, OH 43035</b-card-text>-->
          <!--          <b-card-text>UTC-5: Eastern Standard Time (EST)</b-card-text>-->
          <!--          <b-card-text>202-555-0140</b-card-text>-->
          <b-button variant="primary" block @click="$emit('next-step')" :disabled="isLoading || invalid">
            Suivant
            <feather-icon icon="ArrowRightIcon" class="ml-50"/>
          </b-button>
        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import {required, integer} from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import {ref, computed} from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import {French} from "flatpickr/dist/l10n/fr";
import store from "@/store";

export default {
  name: "StoreViewItemsCheckoutStepAddress",
  components: {
    BFormTextarea,
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    flatPickr,
    vSelect,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  setup(_, {emit}) {
    const isLoading = ref(false);
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(
        () => {
        }
    );

    const flatConfig = computed(() => {
      return {
        wrap: true,
        altFormat: "d-m-Y",
        dateFormat: "d-m-Y",
        // defaultDate: new Date(),
        locale: French, // locale for this instance only
      };
    });

    const projectsList = ref([]);

    const fetchProjects = async () => {
      isLoading.value = true;
      const projects = await store.dispatch("project/allProjects");
      if(projects.length === 0) projectsList.value = [
        { label: "Aucun projet retrouvé...", value: null },
      ];
      projectsList.value = projects.map((p) => {
        return {
          value: p.uid,
          label: p.code,
        };
      });
      isLoading.value = false;
    }

    fetchProjects();

    const onSubmit = () => {
      emit("next-step");
    };

    return {
      isLoading,
      // Form
      flatConfig,
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,

      // Form Validators
      required,
      integer,

      projectsList,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
